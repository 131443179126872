module.exports = {
	language: "language",
	direction: "direction",
	isLoading: "isLoading",
	isLoggedIn: "isLoggedIn",
	showLogin: "showLogin",
	showSignup: "showSignup",
	allowSignups: "allowSignups",
	showSimilarAds: "showSimilarAds",
	showAnnouncementBar: "showAnnouncementBar",
	announcement: "announcement",
	maintenanceMode: "maintenanceMode",
	maintenanceMessage: "maintenanceMessage",
	showResetPassword: "showResetPassword",
	showChangePassword: "showChangePassword",
	user: "user",
	cookie: "cookie",
	token: "token",
	currentLocation: "currentLocation",
	startConversation: "startConversation",
	usersCityId: "usersCityId",
	searchCityId: "searchCityId",
	searchCategoryId: "searchCategoryId",
	searchKeyword: "searchKeyword"
};
