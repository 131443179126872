import React, { useEffect, useState, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import keys from "./store/keys";
import { setKey, getKey, clearKeys } from "./store/actions";
import settingService from "./services/setting";
const Header = React.lazy(() => import("./components/Header"));
const Login = React.lazy(() => import("./components/Login"));
const ToS = React.lazy(() => import("./components/ToS"));
const AboutUs = React.lazy(() => import("./components/AboutUS"));
const Loading = React.lazy(() => import("./components/Loading"));
const Signup = React.lazy(() => import("./components/Signup"));
const ResetPassword = React.lazy(() => import("./components/ResetPassword"));
const Activate = React.lazy(() => import("./components/Activate"));
const UpdatePassword = React.lazy(() => import("./components/UpdatePassword"));
const Profile = React.lazy(() => import("./components/Profile"));
const UserProfile = React.lazy(() => import("./components/UserProfile"));
const ProfileEdit = React.lazy(() => import("./components/ProfileEdit"));
const Settings = React.lazy(() => import("./components/Settings"));
const Ad = React.lazy(() => import("./components/Ad"));
const Home = React.lazy(() => import("./components/Home"));
const MyAds = React.lazy(() => import("./components/MyAds"));
const MyFavouriteAds = React.lazy(() => import("./components/MyFavouriteAds"));
const AdView = React.lazy(() => import("./components/AdView"));
const Chat = React.lazy(() => import("./components/Chat"));
const Offers = React.lazy(() => import("./components/Offers"));
const Footer = React.lazy(() => import("./components/Footer"));
const ContactUs = React.lazy(() => import("./components/ContactUs"));
const AnnouncementBar = React.lazy(() =>
	import("./components/AnnouncementBar")
);
const MaintenanceMode = React.lazy(() =>
	import("./components/MaintenanceMode")
);

function App({ session, setKey }) {
	useEffect(() => {
		settingService.get().then(response => {
			setKey(keys.allowSignups, response.data.allowSignups);
			setKey(keys.showAnnouncementBar, response.data.showAnnouncementBar);
			setKey(keys.announcement, response.data.announcement || ``);
			setKey(keys.maintenanceMode, response.data.maintenanceMode);
			setKey(
				keys.maintenanceMessage,
				response.data.maintenanceMessage || ``
			);
			setKey(keys.showSimilarAds, response.data.showSimilarAds);
		});
	}, []);

	if (session.maintenanceMode) return <MaintenanceMode />;

	return (
		<BrowserRouter>
			{session.isLoading && <Loading />}
			{session.showLogin && <Login />}
			{session.allowSignups && session.showSignup && <Signup />}
			{session.showResetPassword && <ResetPassword />}
			<Header />
			{session.showAnnouncementBar && <AnnouncementBar />}
			<div className="page-body">
				<Switch>
					<Route exact path="/" component={Home} />
					<Route exact path="/my-profile" component={Profile} />
					<Route
						exact
						path="/profile/:userId"
						component={UserProfile}
					/>
					<Route exact path="/edit-profile" component={ProfileEdit} />
					<Route exact path="/settings" component={Settings} />
					<Route exact path="/my-ads" component={MyAds} />
					<Route
						exact
						path="/my-favorite-ads"
						component={MyFavouriteAds}
					/>
					<Route exact path="/ad" component={Ad} />
					<Route exact path="/ad/:adId" component={Ad} />
					<Route exact path="/ads/:adId" component={AdView} />
					<Route exact path="/chat" component={Chat} />
					<Route exact path="/contact-us" component={ContactUs} />
					<Route exact path="/aboutUs" component={AboutUs} />
					<Route path="/activate" component={Activate} />
					<Route path="/rp" component={UpdatePassword} />
					<Route path="/offers" component={Offers} />
				</Switch>
			</div>
			<Footer />
		</BrowserRouter>
	);
}

const mapStateToProps = store => ({
	session: store.session
});

const mapDispatchToProps = dispatch => ({
	setKey: (key, value) => dispatch(setKey(key, value)),
	getKey: key => dispatch(getKey(key)),
	clearKeys: () => dispatch(clearKeys())
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
