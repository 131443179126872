import { SET_KEY, GET_KEY } from "./actions";
import session from "./session";
import keys from "./keys";

const initialState = {
	isLoggedIn: session.get(keys.isLoggedIn) == "true" ? true : false,
	language: session.get(keys.language) || "ar",
	direction: session.get(keys.direction) || "rlt",
	isLoading: false,
	showLogin: false,
	showSignup: false,
	allowSignups: true,
	showSimilarAds: true,
	showAnnouncementBar: false,
	announcement: ``,
	maintenanceMode: false,
	maintenanceMessage: ``,
	showResetPassword: false,
	showChangePassword: false,
	user: session.getParsed(keys.user) || null,
	cookie: session.get(keys.cookie) || ``,
	token: session.get(keys.token) || ``,
	currentLocation: session.get(keys.currentLocation) || ``,
	startConversation: session.get(keys.startConversation) || null,
	usersCityId:
		(session.getParsed(keys.user) && session.getParsed(keys.user).cityId) ||
		``,
	searchCityId: `nationwide`,
	searchCategoryId: ``,
	searchKeyword: ``
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_KEY:
			return {
				...state,
				[action.payload.key]: action.payload.value
			};

		case GET_KEY:
			return {
				...state
			};

		default:
			return state;
	}
};

export default reducer;
